import { useWeb3React } from '@web3-react/core'
import BigNumber from 'bignumber.js'
import { useCallback, useEffect, useState } from 'react'
import toast from 'react-hot-toast'

import { useNftContract, useSellNftContract } from '../../hooks/useContract'
import useBalances from '../../hooks/useBalance'
import Modal from '../Modal'
import ModalConnectWallet from '../ModalConnectWallet'
import Button from '../LandingPage/Button'
import { DEFAULT_REF } from '../../lib/constants'
import { ethers } from 'ethers'
import Nft from './Nft'

function BuyBox({ typebox, onClick }: any) {
  const { account } = useWeb3React()
  const balances = useBalances()

  const [selectedToken, setSelectedToken] = useState('MATIC')
  const [priceWei, setPriceWei] = useState('0')
  const [amount, setAmount] = useState('1')
  const [isApproved, setIsApproved] = useState(false)
  const [isOpening, setIsOpening] = useState(false)
  const [isOpenBoxSuccess, setIsOpenBoxSuccess] = useState(false)
  const [openedNftId, setOpenedNftId] = useState(null)

  const priceEth = new BigNumber(priceWei).toFixed(4)
  const estTotal = new BigNumber(priceWei).times(amount).toFixed(4)

  const sellNftContract = useSellNftContract()
  const nftContract = useNftContract()

  const getData = useCallback(async () => {
    const proceBoxByUSD = await sellNftContract.priceBox(typebox)
    const rateMaticUsd = await sellNftContract.getBNBPrice()

    const priceMatic = new BigNumber(proceBoxByUSD.toString())
      .dividedBy(new BigNumber(rateMaticUsd.toString()).dividedBy(1e18).toFixed(2))
      .dividedBy(1e18)
      .toFormat(4)

    setPriceWei(priceMatic.toString())
    setIsApproved(true)
  }, [account, nftContract, sellNftContract, typebox])

  const handleApprove = async () => {
    try {
      let tx = null as any
      switch (selectedToken) {
        case 'USDC':
          // tx = await usdcContract.approve(sellNftContract.address, ethers.constants.MaxUint256)
          break
        default:
          break
      }
      const finish = async () => {
        await tx.wait()
        setIsApproved(true)
      }
      toast.promise(finish(), {
        loading: 'Process approve...',
        success: 'Approved',
        error: 'Approve error'
      })
    } catch (error) {
      console.log(error)
      //@ts-ignore
      toast.error(error?.data?.message || error?.message)
    }
  }

  const handleBuyNft = useCallback(async () => {
    const storageRef = localStorage.getItem('referral') || DEFAULT_REF
    const ref = ethers.utils.isAddress(storageRef) ? storageRef : DEFAULT_REF

    try {
      let tx = null as any
      switch (selectedToken) {
        case 'MATIC':
          tx = await sellNftContract.buyBoxByBNB(typebox, amount, ref, {
            value: new BigNumber(estTotal).times(1e18).times(1.1).toFixed(0),
            gasLimit: 355000 + (195000 * parseInt(amount, 10))
          })
          break
        // case 'USDC':
        //   tx = await sellNftContract.buyBoxByUSD(typebox || 0, usdcAddress, amount, ref, {
        //     gasLimit: 350000
        //   })
        //   break

        default:
          throw new Error('Wrong token')
      }

      setIsOpening(true)
      const finish = async () => {
        const res = await tx.wait()
        setTimeout(() => {

          setIsOpening(false)
        }, 3000)
        if (res.status === 0) throw new Error('Tx failed')

        setIsOpenBoxSuccess(true)

        const filter = nftContract.filters.NewNFTMint(account)
        const history = await nftContract.queryFilter(filter, res.blockNumber)
        for (const historyData of history) {
          const { user, tokenID, instance, mintAt } = historyData.args
          //@ts-ignore
          setOpenedNftId(instance.toString())
        }
      }

      toast.promise(finish(), {
        loading: 'Processing...',
        success: 'Success',
        error: () => {
          setIsOpening(false)
          return 'Open box error'
        }
      })
    } catch (error) {
      console.log(error)
      //@ts-ignore
      toast.error(error?.data?.message || error?.message)
    }
  }, [amount, estTotal, nftContract, selectedToken, sellNftContract, typebox])

  const handleChangeQuantity = (e: any) => {
    if (Number(e.target.value) > 0) {
      setAmount(e.target.value)
    } else {
      setAmount('')
    }
  }

  useEffect(() => {
    if (typebox) {
      getData()
    }
  }, [sellNftContract, typebox])

  return (
    <Modal onClick={!account ? null : onClick}>
      <div className="flex flex-col gap-6 p-8">
        <div className="gap-6 md:flex">
          <div className="w-[230px]">
            <img src="/images/landingpage/mbappe7.png" />
          </div>
          <div className="mt-4 flex-col md:mt-0">
            <p className="text-2xl font-bold text-white">Young Mystery Mbappe</p>
            <div className="mt-5">
              <p className="text-[#A9ACBE]">Price</p>
              <div className="mt-2 flex items-baseline justify-between">
                <div className="flex">
                  <div className="mr-2 h-7 w-7 self-center">
                    <img src="/images/tokens/MATIC.svg" />
                  </div>
                  <p className="self-center text-3xl font-bold text-white">{priceWei}</p>
                </div>
                {/* <div
                  className="flex cursor-pointer items-center rounded-md border-solid p-2 duration-300 hover:scale-150"
                  onClick={() => setOpenSelectCurrency(!openSelectCurrency)}>
                  <IconDown />
                </div> */}
              </div>
            </div>
            {/* {openSelectCurrency && <SelectSymbol handleSelect={handleSelectCurrency} />} */}

            <div className="mt-5">
              <div className="flex items-center justify-between">
                <p className="text-[#A9ACBE]">Quantity</p>
                <p className="text-base text-[#A9ACBE]">{`Balance: ${balances}`}</p>
              </div>
              <input
                type="number"
                className="mt-2 border border-solid border-[#192037] bg-[#020D22] px-3 py-2 text-white outline-none"
                onChange={handleChangeQuantity}
                value={amount}
              />
            </div>
          </div>
        </div>
        <div className="h-1 w-full rounded-full bg-[#363C5B]" />
        <div className="flex items-center justify-center">
          {/* {!account && <ConnectWalletButton scale="sm" />} */}
          {/* {account ? (
            !isApproved ? (
              <Button disabled={isLoading} onClick={handleApprove}>
                Approve
              </Button>
            ) : (
              <Button disabled={isLoading} onClick={handleConfirm}>
                Buy Card
              </Button>
            )
          ) : (
            <ConnectWalletButton scale="sm" />
          )} */}
          {account ? (
            <div className="mt-9">
              {isApproved ? (
                <div className="w-fit">
                  <Button
                    className="px-8 py-2 font-bold duration-300 hover:scale-105"
                    onClick={handleBuyNft}
                  >
                    OPEN SAFE
                  </Button>
                </div>
              ) : (
                <div className="w-fit">
                  <Button className="px-8 py-2 font-bold duration-300 hover:scale-105" onClick={handleApprove}>
                    APPROVE
                  </Button>
                </div>
              )}
            </div>
          ) : (
            <div className="mt-9 w-fit">
              <Button
                className="p-2 font-bold duration-300 hover:scale-105"
                // onClick={handleApprove}
              >
                <ModalConnectWallet
                  label={account ? `${account.substring(0, 6)}...${account.substring(account.length - 4)}` : 'Connect wallet'}
                />
              </Button>
            </div>
          )}
        </div>
      </div>
      {isOpening && (
        <video
          className="fixed inset-0 z-[49] h-screen w-screen object-fill"
          muted
          // controls={false}
          loop
          autoPlay>
          <source src={`https://storage.googleapis.com/mbappe/assets/Idle.mp4`} type="video/mp4" />
        </video>
      )}
      {isOpenBoxSuccess && (
        <video
          className="fixed inset-0 z-[49] h-screen w-screen object-fill"
          muted
          autoPlay
          onEnded={() => {
            setIsOpenBoxSuccess(false)
          }}>
          <source src={`https://storage.googleapis.com/mbappe/assets/Open.mp4`} type="video/mp4" />
        </video>
      )}
      {openedNftId && (
        <div className="bg-[gba(26, 30, 36, 0.3)] fixed inset-0 flex items-center justify-center" style={{ backdropFilter: 'blur(32px)' }}>
          <div className="flex flex-col">
            {/* <img src={`https://storage.googleapis.com/money-nft/nft/${openedNftId}.jpg`} /> */}
            <Nft instance={openedNftId}/>
            <button onClick={() => setOpenedNftId(null)} className="mt-10 rounded-md bg-white px-4 py-2 font-bold">
              Claim NFT
            </button>
          </div>
        </div>
      )}
    </Modal>
  )
}

export default BuyBox
