import * as React from "react"

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={60}
      height={69}
      viewBox="0 0 60 69"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M60 18.991a1.76 1.76 0 00-1.758-1.761h-7.627v3.523h7.627A1.755 1.755 0 0060 18.991zM7.972 29.678a4.454 4.454 0 01-2.83-1.018 4.468 4.468 0 01-1.624-3.457A4.565 4.565 0 014.89 22.05a4.549 4.549 0 013.18-1.297h1.317V17.23H8.103a8.087 8.087 0 00-5.71 2.352A8.114 8.114 0 000 25.285c.01.991 0 4.1 0 4.833v34.809a4.088 4.088 0 001.194 2.876A4.074 4.074 0 004.066 69h51.87a4.073 4.073 0 002.87-1.197A4.087 4.087 0 0060 64.927v-8.84H42.655a8.541 8.541 0 01-6.091-2.557 8.569 8.569 0 01-2.458-6.14 8.656 8.656 0 012.62-5.99 8.626 8.626 0 016.057-2.441H60v-8.84c0-.148-.007-.295-.022-.44H7.972z"
        fill="#2D3E50"
      />
      <path
        d="M36.303 47.522a6.376 6.376 0 001.862 4.498 6.354 6.354 0 004.49 1.865H60V41.162H42.655a6.352 6.352 0 00-4.49 1.864 6.375 6.375 0 00-1.862 4.496zm10.473-1.643a1.716 1.716 0 011.718 1.722 1.724 1.724 0 01-1.718 1.721h-3.819a1.717 1.717 0 01-1.718-1.721 1.723 1.723 0 011.718-1.722h3.819zM19.544 0h-3.089a4.325 4.325 0 00-4.001 2.678 4.346 4.346 0 00-.33 1.66v22.27h7.42V0zM47.876 4.338c0-1.15-.456-2.253-1.268-3.067A4.327 4.327 0 0043.546 0H25.28v26.608h22.596V4.338zM43.79 17.424a1.475 1.475 0 01-1.473 1.476h-3.456a1.473 1.473 0 01-1.475-1.476V5.578a1.478 1.478 0 011.475-1.476h3.456a1.47 1.47 0 011.361.91c.074.18.112.372.112.566v11.846z"
        fill="#2E79BD"
      />
    </svg>
  )
}

export default SvgComponent
