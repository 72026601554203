import { useWeb3React } from '@web3-react/core'
import { useState } from 'react'
import Button from '../LandingPage/Button'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import Box from './Box'
import BuyBox from './BuyBox'

const config = [
  {
    image: '/images/landingpage/Titan.png',
    title: 'Titan',
    price: '69',
    type: '2'
  },
  {
    image: '/images/landingpage/Platinum.png',
    title: 'Platinum',
    price: '69',
    type: '1'
  },
  {
    image: '/images/landingpage/Diamond.png',
    title: 'Diamonds',
    price: '69',
    type: '0'
  }
]

function Mystery() {
  const { account } = useWeb3React()
  const [openCard, setOpenCard] = useState<{ open: boolean; type: string | null }>({ open: false, type: null })
  const refLink = `${process.env.REACT_APP_BASE_URL}/mystery-card?ref=${account}`

  const handleCopy = () => {
    navigator.clipboard.writeText(refLink)
  }

  return (
    <div className="">
      <div className="mt-9 flex justify-center">
        <p className="text-5xl font-bold text-white">Mystery Card</p>
      </div>
      <div className="mx-auto mt-10 max-w-6xl px-4">
        <p className="mb-3 text-white">
          Your ref purchases mystery card on NFT marketplace, you will earn 10% commission (of total box value)
        </p>
        <div className="flex items-center ">
          <p className="truncate text-sm text-white">{refLink}</p>
          <CopyToClipboard text={refLink} onCopy={handleCopy}>
            <Button
              variant="text"
              className="mx-3 items-center bg-contain px-6 text-center text-xs duration-300 active:translate-y-1 lg:text-base"
              style={{
                backgroundSize: 'contain',
                backgroundImage: 'url(/images/landingpage/button-bg.png)',
                backgroundRepeat: 'no-repeat',
                cursor: 'pointer'
              }}>
              copy
            </Button>
          </CopyToClipboard>
        </div>
        <div className="relative">
          <div className="flex flex-col">
            <div className="my-16 grid grid-cols-1 items-center gap-12 md:grid-cols-3">
              {config.map((item, index) => (
                <Box key={`card-${index}`} onClick={() => setOpenCard({ open: true, type: item.type })} typebox={item.type} item={item} />
              ))}
            </div>
          </div>
          {openCard.open && <BuyBox typebox={openCard.type} onClick={() => setOpenCard({ open: false, type: null })} />}
        </div>
      </div>
    </div>
  )
}
export default Mystery
