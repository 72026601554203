import React from 'react'
import IconCard from '../icons/IconCard'
import IconNfts from '../icons/IconNfts'
import IconWallet from '../icons/IconWallet'

const config = [
  {
    icon: <IconWallet />,
    title: 'Set up your wallet',
    des: 'Please make sure that you connect your wallet with our site by BSC network and have BNB for paying fee when performing transaction.',
  },
  {
    icon: <IconCard />,
    title: 'Buy mystery card',
    des: 'Go to the marketplace and choose your mystery box you love, click Buy Now to become an owner of secret NFT hidden in this box.',
  },
  {
    icon: <IconNfts />,
    title: 'Manage your NFT collections',
    des: 'To track all information of your NFTs, you can view at My NFT page, which displays contract, token ID, artist and transaction history.',
  },
]

function Purchase() {
  return (
    <div className="relative">
      <div className="flex-col max-w-5xl mx-auto overflow-hidden justify-center pt-44">
        <p className="text-white font-bold text-6xl text-center">Create and sell your NFTs</p>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-12 mt-16">
          {config.map((item, index) => {
            return (
              <div
              key={`purchase-item-${index}`}
                className="flex flex-col items-center justify-center  pt-10 pb-5 px-5"
                style={{
                  background: 'rgba(38, 86, 130, 0.24)',
                }}
              >
                <div className="flex flex-col items-center justify-center text-center">
                  <div className="flex w-[120px] h-[120px] rounded-full bg-white justify-center self-center items-center">
                    {item.icon}
                  </div>
                  <p className="text-white font-bold text-2xl mt-12 mb-5">{item.title}</p>
                  <p className="text-white text-lg">{item.des}</p>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
export default Purchase
