import * as React from "react"

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={71}
      height={71}
      viewBox="0 0 71 71"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M62.28 32.605H8.71a6.368 6.368 0 00-6.368 6.367v23.441a6.368 6.368 0 006.368 6.368H62.28a6.368 6.368 0 006.368-6.368v-23.44a6.368 6.368 0 00-6.368-6.368z"
        fill="#2D3E50"
      />
      <path
        d="M10.728 26.836h49.545a2.219 2.219 0 000-4.438H10.728a2.22 2.22 0 000 4.438zM18.626 16.485h33.748a2.219 2.219 0 100-4.437H18.626a2.219 2.219 0 100 4.437zM28.467 6.656h14.067a2.219 2.219 0 000-4.437H28.467a2.219 2.219 0 100 4.437z"
        fill="#2E79BD"
      />
    </svg>
  )
}

export default SvgComponent
