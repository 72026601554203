import { Link } from 'react-router-dom'
// @ts-ignore
// import wpp from '../../../../whitepaper.pdf'
import Button from './Button'
import HeroName from './HeroName'

function Hero() {
  return (
    <div className="relative md:pt-32 pt-60">
      <div className="absolute pointer-events-none w-[650px] 2xl:left-[10%]">
        <img src="/images/landingpage/shoe.png" />
      </div>
      <div className="relative grid max-w-5xl grid-cols-1 mx-auto overflow-hidden md: md:grid-cols-2 gap-3 px-4">
        <div className="relative z-10 flex flex-col justify-center py-8">
          <div>
            <div className="flex gap-3">
              <p className="text-6xl font-semibold text-white md:text-5xl">Kylian</p>
              <div className="w-2" style={{ background: 'linear-gradient(270deg, #AD61E2 0%, #D78096 100%)' }} />
              <p className="text-6xl font-semibold text-white md:text-5xl">10</p>
            </div>
            <div className="flex-col">
              <HeroName />
              <p className="max-w-md mt-4 text-white">
                From young football player with no fame to a big star, each of big milestone in Mbappe' career is the
                proof of his meteoric effort. Stand by Mbappe' and support him!
              </p>
            </div>
            <div className="flex justify-between mr-10 mt-4">
              <div className="text-white">
                <p className="font-bold text-4xl">40k+</p>
                <p>Artwork</p>
              </div>
              <div className="text-white">
                <p className="font-bold text-4xl">28k+</p>
                <p>Auction</p>
              </div>
              <div className="text-white">
                <p className="font-bold text-4xl">12k+</p>
                <p>Artist</p>
              </div>
            </div>
            <div className="flex gap-4 mt-10">
              <Link to="/mystery-card">
                <Button>Buy Card</Button>
              </Link>
              <Link to="https://opensea.io/collection/mbappe-nft-v2" target="_blank">
                {/* <Button variant={'secondary'}>{'Whitepaper'}</Button> */}
                <Button bg="black" >Marketplace</Button>
              </Link>
            </div>
          </div>
        </div>

        <div className="justify-center hidden md:flex">
          <img src="/images/landingpage/hero-img.png" />
        </div>

        {/* <img src="/images/musk-potrair-2.png" className="absolute bottom-0 right-0 w-[450px] -mb-8" /> */}
      </div>
    </div>
  )
}

export default Hero
