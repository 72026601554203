import React from 'react'

function Nft({ instance }: any) {
  const [images, setImages] = React.useState<any>([])

  React.useEffect(() => {
    if (instance) {
      const images = getDna(instance)
      setImages(images)
    }
  }, [instance])

  const [firstImage, ...restImages] = images

  return (
    <div className="relative flex h-[400px] w-full flex-col items-center gap-6">
      <div className="w-[330px] flex-col items-center justify-center rounded-lg">
        <div className="relative">
          <img className="absolute inset-0" src={firstImage} />
          {restImages.map((img: any, index: any) => {
            return <img className="absolute inset-0" src={img} key={`buyed-${index}`} />
          })}
        </div>
      </div>
    </div>
  )
}
export default Nft
export function getDna(dna: string) {
  const assetPath = '/assets'

  const [bg, body, hand, mouth, skin, type] = dna.split(',')

  // const fullDna = [hair, hat, bg, glass, frame, body, skin]
  const fullDna = [bg, body, mouth, skin, type, hand]
  // const fullDnaKey = ['hair', 'hat', 'bg', 'glass', 'frame', 'body', 'skin']
  const fullDnaKey = ['bg', 'body', 'mouth', 'skin', 'type', 'hand']

  return fullDnaKey.map((item, index) => `${assetPath}/${item}/${item}${fullDna[index]}.png`)
}
