import React from 'react'
import IconLoading from '../icons/IconLoading'

function Button({ bg = 'white', ...props }) {
  const className = [
    // props.disabled ? 'animate-spin' : 'animate-none',
    'flex justify-center items-center py-2 text-lg font-bold min-w-[166px] duration-300 hover:-translate-y-1 active:translate-y-0',
  ].join(' ')

  return (
    <button
      style={{
        backgroundImage: bg === 'white' ? 'url(/images/landingpage/button-bg.png)' : 'url(/images/landingpage/bg-btn-black.png)',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        cursor: 'pointer',
      }}
      className={className}
      {...props}
    >
      <div className="flex justify-center items-center gap-2">
        {props.disabled && <IconLoading className="animate-spin h-5 w-5" />}
        {bg === 'white' ? (
          <p
            style={{
              background: '-webkit-linear-gradient(270deg, #AD61E2 50%, #767AAE 100%)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
            }}
          >
            {!props.disabled ? props.children : 'Processing...'}
          </p>
        ) : (
          <p className="text-white">{!props.disabled ? props.children : 'Processing...'}</p>
        )}
      </div>
    </button>
  )
}

export default Button
