import * as React from "react"

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={69}
      height={69}
      viewBox="0 0 69 69"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M47.525 25.167v-3.692h-3.692c-1.938 0-3.765-.768-5.155-2.12-1.352-1.39-2.12-3.218-2.12-5.265 0-1.316.365-2.522.987-3.582H7.312C3.29 10.508 0 13.797 0 17.819v43.87C0 65.71 3.29 69 7.312 69h43.87c4.02 0 7.31-3.29 7.31-7.311v-30.27c-1.096.621-2.339 1.023-3.728 1.023-3.985-.036-7.239-3.29-7.239-7.275zM47.38 61.69H10.967c-1.498 0-2.376-1.719-1.462-2.925l7.239-9.615c.767-1.023 2.266-.95 2.997.073l5.85 8.81 9.541-12.721c.731-.95 2.157-.987 2.888-.037l10.785 13.453c.95 1.207.11 2.962-1.426 2.962z"
        fill="#2D3E50"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M54.28 0a3.68 3.68 0 00-3.68 3.68v7.36h-7.36a3.68 3.68 0 100 7.36h7.36v7.36a3.68 3.68 0 107.36 0V18.4h7.36a3.68 3.68 0 000-7.36h-7.36V3.68A3.68 3.68 0 0054.28 0z"
        fill="#2E79BD"
      />
    </svg>
  )
}

export default SvgComponent
