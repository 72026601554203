import React, { useRef } from 'react'
import useOnClickOutside from '../hooks/useOnClickOutside'

function Modal(props: any) {
  const wrapperRef = useRef(null)
  useOnClickOutside(wrapperRef, props.onClick)

  return (
    <div className="fixed top-0 left-0 bg-[#050920] h-full w-full z-20">
      <div className="flex flex-col items-center justify-center">
        <div
          ref={wrapperRef}
          className="flex-col m-auto mt-[20vh]"
          style={{
            background: '#020D22',
            boxShadow: '2px 12px 42px rgba(111, 194, 230, 0.19)',
          }}
        >
          {props.children}
        </div>
      </div>
    </div>
  )
}
export default Modal
