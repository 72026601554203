import React from 'react'
import styled from 'styled-components'
import Button from './Button'
import HeroName from './HeroName'

const StyleImage = styled.img`
  box-shadow: 0px 12px 42px rgba(111, 194, 230, 0.19);
`

function Artwork() {
  return (
    <div className="relative mt-44">
      <div className="absolute top-[-400px] pointer-events-none">
        <img src="/images/landingpage/ballflying.png" />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-3 max-w-5xl mx-auto overflow-hidden justify-center">
        <div className="flex gap-3">
          <div className="flex flex-col gap-3">
            <StyleImage src="/images/landingpage/mbappe1.png" />
            <StyleImage src="/images/landingpage/mbappe4.png" />
          </div>
          <div className="flex flex-col gap-3">
            <div className="flex gap-3">
              <StyleImage src="/images/landingpage/mbappe2.png" className="max-w-[230px] max-h-[180px]" />
              <StyleImage src="/images/landingpage/mbappe3.png" className="max-w-[125px]" />
            </div>
            <StyleImage src="/images/landingpage/mbappe5.png" />
          </div>
        </div>
        <div className="px-4">
          <div className="flex gap-4">
            <p className="text-white text-5xl lg:text-6xl font-bold">Get more</p>
            <div className="w-[160px] lg:w-[200px]">
              <HeroName />
            </div>
          </div>
          <p className="text-white text-5xl lg:text-6xl font-bold">artwork here</p>
          <p className="text-white text-xl mt-12">
            His awesome dribbling, fantastic speed, and amazing finishing create a great performance on the turf field!
            Let's find some unique NFT of Mbappe' here!
          </p>

          <div className="mt-16">
            <a href="/mystery-card">
              <Button>Explore now</Button>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Artwork
