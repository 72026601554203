import React from "react"
import IconTelegram from "./icons/IconTelegram"
import IconTwitter from "./icons/IconTwitter"
import IconYoutube from "./icons/IconYoutube"
import Logo1 from "./icons/Logo1"
// import LogoWithText from "./LogoWithText"

function Footer() {
  return (
    <div className="relative bg-[#0B0F24] pb-10">
      <div className="max-w-5xl grid grid-cols-2 md:grid-cols-3 m-auto text-white py-14 gap-6 px-4 md:px-0">
        <div>
          <Logo1 />
          <p className="text-[#A9ACBE] my-5 md:text-xl">
            From young French football player with no frame to a big star, Mbappe's meteoric rise in his career is the
            massive effort. He’s a legend!
          </p>
        </div>
        <div className="md:ml-8">
          <div className="flex justify-between mt-5">
            {/* <p className="font-bold text-xl md:text-2xl">Resource</p> */}
            <p className="font-bold text-xl md:text-2xl">Social channel</p>
          </div>
          <div className="flex justify-between mt-5">
            {/* <a href={mbappeWpp} target="_blank">
              <p className="text-sm md:text-base text-[#A9ACBE] my-3">whitepaper</p>
            </a> */}
            <div className="">
              <a href="https://m.youtube.com/channel/UCKUhGD8rZbuRkM_rZVMGO_Q?channel_created=1" target='_blank'>
                <div className="flex gap-5 items-center">
                  <IconYoutube />
                  <p className="text-sm md:text-xl text-[#A9ACBE] my-3">Youtube</p>
                </div>
              </a>
              <a href="https://twitter.com/MbappeNFTs" target='_blank'>
                <div className="flex gap-5 items-center">
                  <IconTwitter />
                  <p className="text-sm md:text-xl text-[#A9ACBE] my-3">Twitter</p>
                </div>
              </a>
              <a href="https://t.me/Mbappe_Official_Channel" target='_blank'>
                <div className="flex gap-5 items-center">
                  <IconTelegram />
                  <p className="text-sm md:text-xl text-[#A9ACBE] my-3">Telegram Channel</p>
                </div>
              </a>
              <a href="https://t.me/Mbappe_Official_Community" target='_blank'>
                <div className="flex gap-5 items-center">
                  <IconTelegram />
                  <p className="text-sm md:text-xl text-[#A9ACBE] my-3">Telegram Group</p>
                </div>
              </a>
            </div>
          </div>
        </div>
        <div className='col-span-2 md:col-span-1'>
          <div className="flex justify-between mt-5">
            <p className="font-bold text-xl md:text-2xl">Get in touch with us</p>
          </div>
          <div className="flex-col mt-5">
            <div className="gap-2">
              <p className="text-[#A9ACBE] text-base">Name</p>
              <input
                className="w-full bg-[#23273E] px-4 py-3 text-base outline-none"
                type="text"
                placeholder="Enter your name"
              />
            </div>
            <div className="gap-2 mt-6">
              <p className="text-[#A9ACBE] text-base">Email address</p>
              <div className="flex gap-2">
                <input
                  className="bg-[#23273E] px-4 py-3 text-base outline-none flex-2"
                  type="text"
                  placeholder="Enter your email address"
                />
                <button className="font-bold text-xl bg-white text-[#0B0F24] flex-1 active:translate-y-1 duration-300">Subscribe</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
