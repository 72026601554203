import { Web3ReactHooks } from "@web3-react/core"
import BigNumber from "bignumber.js"
import { useEffect, useState } from "react"
import { hooks } from '../connectors/metaMask'

export default function useBalances() {
  const { useAccounts, useProvider } = hooks
  const provider = useProvider()
  const accounts = useAccounts()
  const [balances, setBalances] = useState<BigNumber[] | undefined>()

  useEffect(() => {
    if (provider && accounts?.length) {
      let stale = false

      void Promise.all(accounts.map((account) => provider.getBalance(account))).then((balances) => {
        if (stale) return
        //@ts-ignore
        setBalances(new BigNumber( balances[0].toString()).dividedBy(1e18).toFormat(4))
      })

      return () => {
        stale = true
        setBalances(undefined)
      }
    }
  }, [provider, accounts])

  return balances
}
