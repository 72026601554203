import React from 'react'
import ReactDOM from 'react-dom/client'
import { createBrowserRouter, RouterProvider, Route } from 'react-router-dom'

import reportWebVitals from './reportWebVitals'
import Web3Provider from './Web3Provider'

import './index.css'
import "@reach/dialog/styles.css"
import "./styles/globals.css"
import "./styles/landingpage.css"
import Home from './pages/Home'
import MyAssets from './pages/MyAssets'
import Safe from './pages/Safe'
import NftPage from './components/Nft/NftPage'
import MysteryCard from './pages/MysteryCard'

const router = createBrowserRouter([
  {
    path: '/',
    element: <Home />
  },
  {
    path: '/mystery-card',
    element: <MysteryCard />,
  },
  {
    path: '/my-assets',
    element: <MyAssets />
  },
  // {
  //   path: '/safe/:typeBox',
  //   element: <NftPage />
  // },
])

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <Web3Provider>
      <RouterProvider router={router} />
      {/* <App /> */}
    </Web3Provider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
