import { useState } from 'react'
import Artwork from './Artwork'
import Feature from './Feature'
import Hero from './Hero'
import Purchase from './Purchase'
import Support from './Support'

function index() {
  return (
    <div
      className="relative leading-normal"
      style={{
        background: 'linear-gradient(90deg, #160834 0%, #081645 100%)',
      }}
    >
      <Hero />
      <Feature />
      <Purchase />
      <Artwork />
      {/* <Team /> */}
      <Support />
    </div>
  )
}

export default index

const teams = [
  {
    img: '/images/home/team/team-ceo.png',
    title: 'CEO: Eleanor Lieberman',
    des: 'A graduate of Massachusetts Institute of Technology  with a background of management consulting and entrepreneurship, Eleanor has been investing in crypto since 2017 and believes that blockchain technology is a great equalizer between centralized power and the community. In 2019, Eleanor joined her hand in building a football tournament for children under the authority of CAFA. She also launched a project related to a football customization company. And for the time being, she has been involved in some football-themed games within the framework of the event promotion campaign of UEFA. She believes that, with all the public interest in cryptocurrency even when the market is facing erratic growth that is not favorable for investors, digital transactions will be the next step and new developments of the future and payment methods in the 21st century.',
  },
  {
    img: '/images/home/team/team-coo.png',
    title: 'COO: Dominique Pieterse',
    des: "Dominique Pieterse is an alumnus of the University of Paris XI, he attended the Faculty of Law-Economics-Management and graduated with a master's degree right after completing the school's training program. From the time he was studying, he was evaluated as a person with strong determination. Right from the second year of school, he has been involved in many technology-related startup projects. His desire is to find a technology that can be applied to the world economy, even replacing the currencies being used today. Immediately after the launch of blockchain technology, Dominique Pieterse immediately saw this as a technology of the future. He believes that the potential of blockchain lies not only in the trading of cryptocurrencies but also in its ability to develop in the future. According to him, the potential of this technology lies in the fact that if there is a chance to develop, it can completely replace conventional currencies, and even more than that. That's why he decided to cooperate with Eleanor Lieberman, a person who, according to him, has a great understanding of new technologies. In 2020, when Paris Saint-Germain was preparing for the Ligue 1 season, Dominique participated in organizing a few activities within the framework of the team's training and promotion campaigns as a professional consultant. In addition, he has been involved in the ConIFA World Football Cup, season 2018 as a supervisor.",
  },
  {
    img: '/images/home/team/team-cto.png',
    title: 'CTO: Andrew Raudenfeld',
    des: 'From a 15-year background in the finance industry and having completed a Master’s degree in Financial Investment at University of Nottingham, Andrew Raudenfeld pivoted to become a sport game data analyst and also pursued a Master’s degree in Data Science at University of Melbourne. He would surprise you with his unbelievable understanding of technology and future trends, and obviously, this talent helps him guide and lead the human resources team to fulfill the strategic targets of the organization. Apart from the specialization, Andrew is a loyal fan of Paris Saint-Germain club. He partook in several events and also contributed to the prosperity of the club in many aspects. Noticeably, he was the technical consultant for the logistics team at Watch Parties of PSG live events! For the time being, he is a technical advisor for the CONCACAF Nations League.  Andrew appreciates the opportunity to work in professional football leagues in the field of technology, especially meeting with reputable senior advisors and supervisors in this field. That way he knows what he has to do to have a successful event as well as a technically created project.',
  },
  {
    img: '/images/home/team/team-cmo.png',
    title: 'CMO: Ivanov Plotnikova',
    des: 'He is the co-founder of some projects and major ecosystem exchange startups established in Russia, focused on blockchain technology for fast, reliable, and big data transaction processing. As a talented alumni of St.Petersburg State Polytechnical University, he established a technology startup when he was a student in 2007 and in his 13 years of IT business management experience including 8 years of operating and promoting blockchain and game projects with many globally famous projects, he has received several distinctions and awards such as the IT Excellence Gold Cup 2010. Ivanov has been featured in several major publications around the world. Ivanov has been working with several world-class sport promoters in his sentire career, he also participated in a multi-material project which cooperated with NF-board - whose main tournament was VIVA World Cup. In addition, Ivanov had a chance of meeting Don King - the legendary boxing promoter through his speech held at Edison Ballroom, New York in 2017.',
  },
  {
    img: '/images/home/team/team-uxui.png',
    title: 'UX/UI Designer: Sophie McKeon',
    des: 'Having completed a Bachelor’s degree of Art in Graphic Design from the Maryland Institute College of Art (MICA) Sophie desires to create wonderful product designs. She started getting into the world of crypto in 2016. She also gained a lot of experience in the time of contributing at Sports Business Journals. Sophie believes that the concept of crypto, decentralized finance, and integrated sports brings a bridge to the future of finance.',
  },
  {
    img: '/images/home/team/team-pm.png',
    title: 'Project manager: Felix Deinert',
    des: 'A crypto investor since 2016, Felix is also an expert in project management, human resources, and business development. He earned a Bachelor’s Degree of Human Resource and Management and Leadership from Cologne Business School. Felix really believes that the future is in blockchain technology and it will be the greatest disruptor in the coming years, helping people succeed in a short amount of time.',
  },
  {
    img: '/images/home/team/team-conten.png',
    title: 'Content & Communication: Amybeth Wright',
    des: 'Amybeth is a Bachelor of Content Creation graduate from Klein College of Media and Communication, Philadelphia, USA. She then has worked with more than 20 businesses from the USA, UK, Australia, Sweden, and Russia, some of which are crypto and fintech startups. Amybeth believes that great things are made by disruptions and crypto is doing just that to the centralized systems.',
  },
  {
    img: '/images/home/team/team-advisor.png',
    title: 'Advisors board: Dr. Alan Sanders',
    des: 'Dr. Alan Sanders holds vast experience in the technological and auditing space ranging over 10 years. He is a graduate in Business Management from University of Portsmouth and completed his PhD on Nanotechnology at the Georgia Institute of Technology. Throughout his career, he has worked in prestigious tech organizations in Germany, USA and China. Alan actively worked in the tech business development space which makes him a truly valuable asset for this project. He was also a member of the UK Youth Parliament.',
  },
  {
    img: '/images/home/team/team-dev.jpg',
    title: 'Lead Developer: Elliot Anderson',
    des: "Having completed his Bachelor of Arts at the Department of Computer Science and Technology, University of Cambridge in 2012, then progressed to a Master's program, Elliot is a highly-skilled developer that we admire. For the time he was at university, Elliot had proven himself to be an outstanding student and truly dedicated to what he is passionate about. He used to hold the position of vice-president in CU Artificial Intelligence Society.",
  },
]

function Team() {
  const [showMore, setShowMore] = useState(null)

  const handleShowMore = (index:any) => {
    setShowMore(index)
  }
  const handleShowLess = (index:any) => {
    setShowMore(null)
  }

  return (
    <div className="relative mt-44">
      <p className='font-bold text-center text-white text-5xl pb-12'>Development Teams</p>
      <div className="grid grid-cols-1 max-w-5xl m-auto md:grid-cols-3 gap-10">
        {teams.map((item, index) => (
          <div key={item.title} className="flex flex-col h-[600px] overflow-hidden">
            <img src={item.img} alt={item.title} />
            <div className="flex bg-white flex-col p-4 flex-1">
              <p className="font-bold text-2xl pb-4">{item.title}</p>
              {showMore !== index ? (
                <p className="">
                  {item.des.slice(0, 130)}
                  {'...'}
                </p>
              ) : (
                <p className="overflow-auto" style={{
                  maxHeight: index === 6 ? '110px' : '140px',
                }}>{item.des}</p>
              )}
              {showMore !== index ? (
                <p
                  className="flex flex-1 justify-end items-end cursor-pointer text-blue-400"
                  onClick={() => handleShowMore(index)}
                >
                  Read More
                </p>
              ) : (
                <p
                  className="flex flex-1 justify-end items-end cursor-pointer text-blue-400"
                  onClick={() => handleShowLess(index)}
                >
                  Read Less
                </p>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
