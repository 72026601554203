import React from 'react'
import HeroName from './LandingPage/HeroName'

function Banner() {
  return (
    <div
      className="relative flex items-center md:pt-24 pt-60"
      style={{
        backgroundImage: `url('/images/landingpage/mbappe-banner.png')`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <div className="relative grid grid-cols-1 md:grid-cols-2 max-w-7xl mx-auto overflow-hidden justify-center">
        <div className='px-4'>
          <HeroName />
          <p className='text-white mt-10 text-base'>
            From young football player with no fame to a big star, each of big milestone in Mbappe' career is the proof
            of his meteoric effort. Stand by Mbappe' and support him!
          </p>
        </div>
        <div className="hidden md:flex">
          <img src="/images/landingpage/hero-banner.png" />
        </div>
      </div>
    </div>
  )
}
export default Banner
