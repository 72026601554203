import * as React from "react"

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={32}
      height={32}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.187 18.813l-2.557 2.73.682 4.77 7.5-5.625-5.625-1.875z"
        fill="#828282"
      />
      <path
        d="M0 13.188l7.563 3.75 5.625 1.875 13.124 11.25L32 1.938 0 13.188z"
        fill="#F2F2F2"
      />
      <path
        d="M7.563 16.938l3.75 9.375 1.874-7.5 13.126-11.25-18.75 9.375z"
        fill="#E0E0E0"
      />
    </svg>
  )
}

export default SvgComponent
