import * as React from "react"

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={32}
      height={26}
      viewBox="0 0 32 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_256_55)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.064 25.6c12.075 0 18.68-9.85 18.68-18.392 0-.28 0-.558-.02-.836A13.224 13.224 0 0032 3.026a13.235 13.235 0 01-3.77 1.017A6.512 6.512 0 0031.117.468c-1.29.753-2.7 1.284-4.17 1.57-2.486-2.603-6.645-2.73-9.29-.281a6.409 6.409 0 00-1.899 6.176C10.48 7.672 5.562 5.218 2.228 1.18.484 4.134 1.375 7.91 4.26 9.807A6.594 6.594 0 011.28 9v.082c.002 3.076 2.205 5.725 5.267 6.335a6.63 6.63 0 01-2.963.11c.86 2.634 3.325 4.438 6.133 4.49A13.304 13.304 0 010 22.697a18.805 18.805 0 0010.064 2.898"
          fill="#F2F2F2"
        />
      </g>
      <defs>
        <clipPath id="clip0_256_55">
          <path fill="#fff" d="M0 0H32V25.6H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SvgComponent
