import { Link } from 'react-router-dom'
import Button from './Button'

function Feature() {
  return (
    <div className="relative">
      <div className="pointer-events-none absolute right-0">
        <img src="/images/landingpage/cup.png" />
      </div>
      <div className="mx-auto max-w-5xl flex-col justify-center pt-44">
        <p className="px-4 text-6xl font-bold text-white">Features Artwork</p>
        <div className="mt-16 grid grid-cols-1 items-center gap-12 md:grid-cols-3">
          <div
            style={{
              backgroundImage: `url('/images/landingpage/bg-card.png')`,
              backgroundSize: 'auto',
              backgroundRepeat: 'no-repeat'
            }}
            className="flex cursor-pointer duration-300 hover:scale-105 hover:p-3">
            <div className="cursor-pointer flex-col bg-white  px-[27px]">
              <img src="/images/landingpage/Titan.png" />
              <div className="my-4">
                <div className="flex-col">
                  <p className="text-3xl font-bold">Titan</p>
                </div>
              </div>

              <div>
                <p>Reserve price</p>
                <div className="flex items-baseline gap-3">
                  <p className="text-3xl font-bold">$10.00</p>
                  {/* <p>($0.00)</p> */}
                </div>
              </div>
              <div className="my-4 border border-solid border-[#E1E3EE]" />
              <Link to="/mystery-card">
                <div className="flex items-center gap-3 py-5">
                  <Button bg="black">Buy Nft</Button>
                  <p>View artwork</p>
                </div>
              </Link>
            </div>
          </div>

          <div
            style={{
              backgroundImage: `url('/images/landingpage/bg-card.png')`,
              backgroundSize: 'auto',
              backgroundRepeat: 'no-repeat'
            }}
            className="flex cursor-pointer duration-300 hover:scale-105 hover:p-3">
            <div className="cursor-pointer flex-col bg-white  px-[27px]">
              <img src="/images/landingpage/Platinum.png" />
              <div className="my-4">
                <div className="flex-col">
                  <p className="text-3xl font-bold">Platinum</p>
                </div>
              </div>

              <div>
                <p>Reserve price</p>
                <div className="flex items-baseline gap-3">
                  <p className="text-3xl font-bold">$100.00</p>
                  {/* <p>($0.00)</p> */}
                </div>
              </div>
              <div className="my-4 border border-solid border-[#E1E3EE]" />
              <Link to="/mystery-card">
                <div className="flex items-center gap-3 py-5">
                  <Button bg="black">Buy Nft</Button>
                  <p>View artwork</p>
                </div>
              </Link>
            </div>
          </div>

          <div
            style={{
              backgroundImage: `url('/images/landingpage/bg-card.png')`,
              backgroundSize: 'auto',
              backgroundRepeat: 'no-repeat'
            }}
            className="flex cursor-pointer duration-300 hover:scale-105 hover:p-3">
            <div className="cursor-pointer flex-col bg-white  px-[27px]">
              <img src="/images/landingpage/Diamond.png" />
              <div className="my-4">
                <div className="flex-col">
                  <p className="text-3xl font-bold">Diamonds</p>
                </div>
              </div>

              <div>
                <p>Reserve price</p>
                <div className="flex items-baseline gap-3">
                  <p className="text-3xl font-bold">$1000.00</p>
                  {/* <p>($0.00)</p> */}
                </div>
              </div>
              <div className="my-4 border border-solid border-[#E1E3EE]" />
              <Link to="/mystery-card">
                <div className="flex items-center gap-3 py-5">
                  <Button bg="black">Buy Nft</Button>
                  <p>View artwork</p>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Feature
