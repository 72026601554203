import { useWeb3React } from "@web3-react/core"
import BigNumber from "bignumber.js"
import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import Button from "../LandingPage/Button"
import { useSellNftContract } from '../../hooks/useContract'

function Box(props: any) {
  // const { account } = useWeb3React()
  const sellNftContract = useSellNftContract()
  const [priceBox, setPriceBox] = useState(new BigNumber('0'))

  async function getPriceBox() {
    const priceBoxUsd = await sellNftContract.priceBox(props.typebox)
    setPriceBox(new BigNumber(priceBoxUsd.toString()))
  }

  useEffect(() => {
    if (props.typebox) {
      getPriceBox()
    }
  }, [sellNftContract, props.typebox])

  return (
    <div
      style={{
        backgroundImage: `url('/images/safe/bg-card-md.png')`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      }}
      className="flex hover:scale-105 hover:p-3 duration-300 cursor-pointer"
      {...props}
    >
      <div className="flex-col bg-white px-[22px]  cursor-pointer">
        <img src={props.item.image} />
        <div className="my-4">
          <div className="flex-col">
            <p className="font-bold text-3xl">{props.item.title}</p>
          </div>
        </div>

        <div>
          <p>Reserve price</p>
          <div className="flex items-baseline gap-3">
            <p className="font-bold text-3xl">${priceBox.div(1e18).toFormat(2)}</p>
            {/* <p>($0.00)</p> */}
          </div>
        </div>
        <div className="border border-solid border-[#E1E3EE] mt-4" />
        <div className="flex gap-3 items-center py-5">
          <Button bg="black">Buy Nft</Button>
          <Link to="">
            <p>View artwork</p>
          </Link>
        </div>
      </div>
    </div>
  )
}
export default Box;
