import * as React from "react"

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={32}
      height={24}
      viewBox="0 0 32 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M26.869 0H5.13C2.297 0 0 2.477 0 5.531v12.938C0 21.524 2.297 24 5.13 24h21.74c2.833 0 5.13-2.477 5.13-5.531V5.531C32 2.477 29.703 0 26.869 0zM12.062 16.85v-9.7l7.876 4.744-7.876 4.957z"
        fill="#FDF3F3"
      />
    </svg>
  )
}

export default SvgComponent
