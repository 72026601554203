import Layout from '../components/Layout'
import MyAssetsPage from '../components/MyAssets/MyAssetsPage'

function MyAssets() {
  return (
    <div className="bg-gradient-to-r from-[#160834] to-[#081645]">
      <Layout>
        <MyAssetsPage />
      </Layout>
    </div>
  )
}

export default MyAssets
