import React from "react"
import Banner from "../components/Banner"
import MysteryBox from "../components/Box/MysteryBox"
import Layout from "../components/Layout"

function MysteryCard() {
  return (
    <div className="overflow-hidden bg-gradient-to-r from-[#160834] to-[#081645]">
      <Layout>
        <Banner />
        <MysteryBox />
      </Layout>
    </div>
  )
}

export default MysteryCard
