import React from 'react'
import Button from './Button'
import HeroName from './HeroName'

function Support() {
  return (
    <div className="relative py-44">
      <div className="grid grid-cols-1 md:grid-cols-2 max-w-5xl mx-auto overflow-hidden bg-[#203063]">
        <div className='md:ml-16 self-center px-4'>
          <p className="text-white font-bold text-5xl">Join us to support</p>
          <div className="w-[375px] mt-2 mb-14">
            <HeroName />
          </div>
          <Button>Join now</Button>
        </div>
        <img src="/images/landingpage/mbappe6.png" />
      </div>
    </div>
  )
}
export default Support
