import * as React from 'react'

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={100}
      height={100}
      viewBox="0 0 100 100"
      {...props}
    >
      <defs>
        <linearGradient id="a" x1="0%" y1="0%" x2="0%" y2="100%">
          <stop offset="0%" stopColor="#00bc9b" />
          <stop offset="100%" stopColor="#5eaefd" />
        </linearGradient>
      </defs>
      <circle
        cx={50}
        cy={50}
        r={47}
        stroke="url(#a)"
        strokeWidth={12}
        fill="none"
        transform="rotate(90 50 50)"
      />
    </svg>
  )
}

export default SvgComponent
