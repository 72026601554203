import { Outlet, Link, useMatch, useLocation } from 'react-router-dom'
// import Link from "next/link"
// import { useRouter } from "next/router"
import { useWeb3React } from '@web3-react/core'
import { Web3Provider } from '@ethersproject/providers'
import ModalConnectWallet from './ModalConnectWallet'
import Button from './LandingPage/Button'
import { useEffect } from 'react'
// import MobileNav from "./MobileNav"

function MainNav() {
  const { account } = useWeb3React()
  useEffect(() => {
    if (typeof window !== 'undefined') {
      console.log("🚀 ~ file: MainNav.tsx ~ line 15 ~ useEffect ~ window", window.innerWidth)
      
      var prevScrollpos = window.pageYOffset
      window.onscroll = function () {
        var currentScrollPos = window.pageYOffset
        if (currentScrollPos < 50) {
          //@ts-ignore
          document.getElementById('main-nav').style.top = '0'
        } else {
          let y = window.innerWidth > 768 ? '-100px' : '-500px'
          //@ts-ignore
          document.getElementById('main-nav').style.top = y
        }
        prevScrollpos = currentScrollPos
      }
    }
  }, [])

  return (
    <div
      id="main-nav"
      className="fixed z-10 flex w-screen items-center justify-start px-2 md:px-4"
      style={{
        background: 'rgba(255, 255, 255, 0.06)'
      }}>
      <div className="px-5 md:px-0 mx-auto flex w-full max-w-6xl items-center justify-between">
        <div className="mr-8 flex items-center rounded-full">
          <Link to="/">
            <div className="cursor-pointer py-4 md:py-0">
              <img src="/logo.svg" className='w-40 md:w-36' />
            </div>
          </Link>
        </div>

        <div className="items-center md:flex">
          <Item href="/">Home</Item>
          <Item href="/mystery-card">Mystery Card</Item>
          <Item href="/my-assets">My Assets</Item>

          <div className="my-4 w-fit">
            <button
              className="bg-cover bg-center bg-no-repeat font-bold duration-300 hover:scale-105"
              style={{
                backgroundImage: 'url(/images/landingpage/bg-btn-topnav.png)'
              }}>
              <div>
                <ModalConnectWallet label={account ? `${account.substring(0, 6)}...${account.substring(account.length - 4)}` : 'Connect'} />
              </div>
            </button>
          </div>
        </div>
      </div>

      {/* <div className="flex items-center md:hidden">
        <div className="py-6 ml-3 md:hidden">
          <MobileNav />
        </div>
      </div> */}
    </div>
  )
}

export default MainNav

function Item({ children, href, ...props }: { children: React.ReactNode; href: string }) {
  const { pathname } = useLocation()
  const isActive = pathname === href

  return (
    <Link to={href || '/'}>
      <div className={`${!isActive ? 'border-b-0' : 'border-b-2'} mx-3 flex py-4`}>
        <p className={`${!isActive ? 'text-[#828282]' : 'font-bold text-white'} cursor-pointer uppercase`}>{children}</p>
      </div>
    </Link>
  )
}
